<template>
  <v-container fluid>
    <monitor-interconnector-reject-table
      :headers="headers"
      :expanded-headers="expanded"
      :data="data"
      :loading="loading"
      :table-page="page"
      :table-page-total-count="pageTotalCount"
      :table-size="size"
      @change="loadData($event.page, $event.size)"
    />
  </v-container>
</template>

<script>
import { getReject } from "@/api/monitor/reject";
import monitorInterconnectorRejectTable from "@/views/monitor/interconnector/components/table";

import { Errors } from "@/utils/error";

export default {
  components: {
    monitorInterconnectorRejectTable,
  },
  data() {
    return {
      headers: [],
      expanded: [],
      data: [],
      loading: true,

      page: 1,
      pageTotalCount: 1,
      size: 25,
      sizeTotalCount: 0,
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
    monitorInterconnectorRejectTable: {
      get() {
        return this.$store.state.tables.monitorInterconnectorRejectTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorInterconnectorRejectTable",
          value: val,
        });
      },
    },
  },
  watch: {
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "monitorInterconnector") {
          this.loadData(this.page, this.size);
        }
        this.changeInAddForm = "";
      },
    },
    monitorInterconnectorRejectTable: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          const self = this;
          this.headers = [];
          newValue.headers.forEach(function (header) {
            if (header.visible) {
              self.headers.push(header);
            }
          });
          this.expanded = [];
          newValue.expanded.forEach(function (item) {
            if (item.visible) {
              self.expanded.push(item);
            }
          });
        }
      },
    },
  },
  created() {
    this.loadData(this.page, this.size);
  },
  methods: {
    loadData(page, size) {
      this.loading = true;
      let newPage = page;
      if (size !== this.size) {
        newPage = 1;
      }
      getReject(newPage, size)
        .then((response) => {
          this.loading = false;
          this.data = response.data;
          this.pageTotalCount = response.last_page;
          this.page = response.current_page;
          this.size = parseInt(response.per_page);
          this.sizeTotalCount = response.total;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
  },
};
</script>
